import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import range from 'lodash/range';
dayjs.extend(localeData);

export const UserRoles = Object.freeze({
  ADMIN: 'admin',
  DESIGNER: 'designer',
  PROVIDER: 'provider',
});

export const VisionDFASetting = Object.freeze({
  ON: 'on',
  OFF: 'off',
});

export const statusTreatmentType = Object.freeze({
  DRAFT: 'draft',
  NEW: 'new',
  IMPRESSION: 'impression',
  AWAITING_SCAN: 'awaiting_scan',
  ASSIGNED: 'assigned',
  DESIGNED: 'designed',
  REVISION: 'revision',
  APPROVED_ADMIN: 'approved_admin',
  APPROVED: 'approved',
  POST_PROCESSED: 'post_processed',
  EXPORTED: 'exported',
  MANUFACTURING: 'manufacturing',
  SHIPPED: 'shipped',
  CANCEL: 'cancel',
  ARCHIVED: 'archived',
});

export const scanTreatmentType = Object.freeze({
  SCAN: 'scan',
  IMPRESSION: 'impression',
});

export const scannerTypes = Object.freeze({
  ITERO: 'ITERO',
  '3Shape': '3Shape',
  Medit: 'Medit',
  Primescan: 'Primescan',
});

export const commentType = Object.freeze({
  MESSAGE: 'message',
  UPDATE: 'update',
  ACTION: 'action',
});

export const durationTypes = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
});

export const versionStatusType = Object.freeze({
  DRAFT: 'draft',
  COMPLETED: 'completed',
  APPROVED_ADMIN: 'approved_admin',
  POST_PROCESSING: 'post-processing',
  POST_PROCESSED: 'post-processed',
  CUSTOMIZING: 'customizing',
  CUSTOMIZED: 'customized',
});

export const FILTER_DEFAULT = {
  value: 'all',
  label: 'All',
};

export const OrderTabs = Object.freeze({
  PATIENT: 0,
  PRESCRIPTION: 1,
  IMAGING: 2,
  SUMMARY: 3,
  TREATMENT: 4,
});

export const monthOptions = dayjs
  .months()
  .map((m, index) => ({ label: m, value: String(index) }));

export const dayOptions = range(1, 31).map((d) => String(d));

export const yearOptions = range(dayjs().year(), 1950, -1).map((y) =>
  String(y)
);

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];
