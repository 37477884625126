import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@/theme';
import * as Sentry from '@sentry/nextjs';
import '@/styles/globals.css';
import { getSession, SessionProvider } from 'next-auth/react';
import { base } from '@/lib/axios';
import App from 'next/app';
import dayjs from 'dayjs';
import Head from 'next/head';
import relativeTime from 'dayjs/plugin/relativeTime';
import { UserRoles } from '@/lib/constants';
dayjs.extend(relativeTime);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // set a global default staleTime of 30 seconds to prevent
      // refetches on every window refocus
      staleTime: 1000 * 30,
    },
  },
});

function OrthodentApp({ Component, pageProps, session }) {
  Sentry.configureScope((scope) => scope.setUser(null));

  const isProd = process.env.NEXT_PUBLIC_BASE_URL?.includes(
    'https://api.myvividportal.com/api/v1'
  );
  const isProvider = session?.user?.role === UserRoles.PROVIDER;
  const SITEID = isProd && 3261559;

  base.interceptors.request.use(async (config) => {
    if (session?.user?.accessToken) {
      config.headers.Authorization = session?.user?.accessToken;
    }
    return config;
  });

  return (
    <SessionProvider {...(session !== undefined ? { session } : {})}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" href="/vivid-site-icon.svg" sizes="32x32" />
        <link rel="icon" href="/vivid-site-icon.svg" sizes="192x192" />
        <link rel="apple-touch-icon" href="/vivid-site-icon.svg" />
        {isProd && (
          <script
            dangerouslySetInnerHTML={{
              __html: ` (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:${SITEID},hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            }}
          />
        )}
        {isProd && isProvider && (
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-na1.hs-scripts.com/22404484.js"
          ></script>
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </SessionProvider>
  );
}

// attach session server-side for every page
// https://github.com/nextauthjs/next-auth/issues/345#issuecomment-1012178372
OrthodentApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { req } = appContext.ctx;

  let session = undefined;
  if (typeof window === 'undefined') session = await getSession({ req });

  // redirect to / if not logged in
  if (
    !session?.user &&
    ![
      '/',
      '/forgot-password',
      '/reset-password',
      '/join',
      '/share-plan/[token]',
    ].includes(appContext.ctx.pathname)
  ) {
    appContext.ctx.res?.writeHead(302, { Location: '/' });
    appContext.ctx.res?.end();
  }

  return {
    ...appProps,
    ...(session !== undefined ? { session } : {}),
  };
};

export default OrthodentApp;
