import { extendTheme } from '@chakra-ui/react';

const config = {
  colors: {
    primary: {
      100: '#003A52',
      200: '#00719D',
      300: '#005B80',
      400: '#eff3f6',
    },
    secondary: {
      50: '#005B8070',
      100: '#fbfdfe',
      400: 'rgba(0, 91, 128, 0.75)',
    },
    blue: {
      50: '#EDF6FA',
      100: '#F7FAFC',
      200: '#ebf6fb',
      300: '#64B1E1',
      400: '#eff6fb',
      500: '#019BD6',
      600: '#008FC7',
    },
    blueAlpha: {
      50: '#EDF6FA80',
      100: '#CEECF270',
    },
    yellow: {
      50: '#fff6dc',
      100: '#fee5b0',
      200: '#fcd381',
      300: '#fbc250',
      400: '#F2E505',
      500: '#e1970a',
      600: '#af7504',
      700: '#7d5401',
      800: '#4c3200',
      900: '#1d1000',
    },

    red: {
      50: '#ffeae7',
      100: '#f0c7c4',
      200: '#e0a59f',
      300: '#d28179',
      400: '#c45e55',
      500: '#aa453b',
      600: '#86342d',
      700: '#612520',
      800: '#3c1512',
      900: '#1c0401',
    },

    tag: {
      navy: {
        bg: '#E7ECED',
        text: '#003A52',
      },
      grey: {
        bg: '#F0F0F0',
        text: '#666666',
      },
      red: {
        bg: '#FCE9E6',
        text: '#C23417',
      },
      orange: {
        bg: '#FEF1E7',
        text: '#D97116',
      },
      gold: {
        bg: '#FAF4E6',
        text: '#C79114',
      },
      green: {
        bg: '#EEF5E3',
        text: '#669900',
      },
      seafoam: {
        bg: '#E1F5F0',
        text: '#00916E',
      },
      teal: {
        bg: '#E2F3F6',
        text: '#007FA0',
      },
      blue: {
        bg: '#E4EDF7',
        text: '#1151A6',
      },
      purple: {
        bg: '#EBE9F6',
        text: '#422E99',
      },
      pink: {
        bg: '#F5E8F3',
        text: '#851E7E',
      },
      blueAlpha: {
        bg: '#f6fcfd',
        text: '#005B80',
      },
      white: {
        bg: '#FFFFFF',
        text: '#005B80',
      },
      magenta: {
        bg: '#FDEDFE',
        text: '#D81B60',
      },
    },
  },
  fonts: {
    primary: {
      heading: '"proxima-nova", sans-serif',
    },
  },
  letterSpacings: {
    primary: {
      tight: '1.4px',
      normal: '1.6px',
    },
    secondary: {
      tight: '0.8px',
      normal: '1.2px',
    },
  },

  components: {
    Tag: {
      parts: ['container', 'label'],
      baseStyle: {
        label: {
          letterSpacing: '1px',
        },
      },
      sizes: {
        sm: {
          label: {
            fontSize: '10px',
            lineHeight: '12px',
          },
        },
      },
    },
  },
};

export const theme = extendTheme(config);
